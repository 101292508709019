
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import ContainerTypeLookUpRequest from "@/store/entities/containercontext/lookuprequest/containertypelookuprequest";
    import IsoCodeLookUpRequest from "@/store/entities/containercontext/lookuprequest/isocodelookuprequest";
    import SortingModel from "@/store/entities/sortingmodel";
    import ContainerForm from "@/views/setting/containercontext/container/container-form.vue";
    import ContainerDto from "@/store/entities/containercontext/container";

    class PageContainerRequest extends PageRequest {
        containerTypeId: number | null;
        isoCodeId: number | null;
        containerSizeId: number | null;
        iIsVerified: boolean | null;
    }

    @Component({
        components: {ContainerForm}
    })
    export default class Container extends AbpBase {
        containerTypes: any;
        isoCodes: any;
        containerSizes: any;

        data() {
            return {
                containerTypes: [],
                isoCodes: [],
                containerSizes: [],
                updateCount: 0,
            }
        };

        containerTypeRequest: ContainerTypeLookUpRequest = new ContainerTypeLookUpRequest();
        isoCodeRequest: IsoCodeLookUpRequest = new IsoCodeLookUpRequest();

        async mounted() {
            this.containerSizes = await this.$store.dispatch('containersize/lookUp');
            this.isoCodes = await this.$store.dispatch('isocode/lookUp');
            this.containerTypes = await this.$store.dispatch('containertype/lookup');
        }

        iIsVerifiedChange(val: string) {
            if (val === 'Yes') {
                this.pagerequest.iIsVerified = true;
            } else if (val === 'No') {
                this.pagerequest.iIsVerified = false;
            } else {
                this.pagerequest.iIsVerified = null;
            }
        }

        async isContainerTypeChange(val: number) {
            (this.$refs.isoCode as any).clearSingleSelect();
            if (val === 0)
                this.pagerequest.containerTypeId = null;
            else
                this.pagerequest.containerTypeId = val;

            this.isoCodeRequest.containerTypeId = this.pagerequest.containerTypeId;
        }

        async search() {
            await this.pageChange(1);
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        async isContainerSizeChange(val: number) {
            (this.$refs.containerType as any).clearSingleSelect();
            if (val === 0)
                this.pagerequest.containerSizeId = null;
            else
                this.pagerequest.containerSizeId = val;

            this.containerTypeRequest.sizeId = this.pagerequest.containerSizeId;
        }

        isISOCodeChange(val: number) {
            if (val === 0)
                this.pagerequest.isoCodeId = null;
            else
                this.pagerequest.isoCodeId = val;
        }

        edit() {
            this.editModalShow = true;
        }

        //filters
        pagerequest: PageContainerRequest = new PageContainerRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.container.list;
        };

        get loading() {
            return this.$store.state.container.loading;
        }

        create() {
            this.$store.commit('container/edit', new ContainerDto());
            this.createModalShow = true;
        }

        async pageChange(page: number) {
            this.$store.commit('container/setCurrentPage', page);
            await this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('container/setPageSize', pagesize);
            this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'container/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.container.pageSize;
        }

        get totalCount() {
            return this.$store.state.container.totalCount;
        }

        get currentPage() {
            return this.$store.state.container.currentPage;
        }

        columns = [{
            title: this.L('Number'),
            key: 'number',
            sortable: 'custom'
        }, {
            title: this.L('Type'),
            key: 'type',
            sortable: 'custom'
        }, {
            title: this.L('ISOCode'),
            key: 'isoCode',
            sortable: 'custom'
        }, {
            title: this.L('Size'),
            key: 'size',
            sortable: 'custom'
        }, {
            title: this.L('Verified'),
            key: 'verified',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: !this.hasEditPermission()
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('container/edit', await this.$store.dispatch({
                                    type: 'container/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small',
                            disabled: !this.hasDeletePermission()
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Container Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'container/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getpage();
        }

        hasAddPermission(){
            return this.hasPermission("Pages.Containers.Add");
        }

        hasEditPermission(){
            return this.hasPermission("Pages.Containers.Edit");
        }

        hasDeletePermission(){
            return this.hasPermission("Pages.Containers.Delete");
        }
    }
